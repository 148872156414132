import {
    REGISTER_USER,
    REGISTER_USER_SUCCESSFUL,
    REGISTER_USER_FAILED,
} from "./actionTypes";

const initialState = {
    registrationError: null,
    message: null,
    loading: false,
};

const account = (state = initialState, action: any) => {
    switch (action.type) {
        case REGISTER_USER:
            state = {
                ...state,

                // @ts-expect-error ts-migrate(2322) FIXME: Object literal may only specify known properties, ... Remove this comment to see the full error message
                user: null,
                loading: true,
                registrationError: null,
            };
            break;
        case REGISTER_USER_SUCCESSFUL:
            state = {
                ...state,
                loading: false,

                // @ts-expect-error ts-migrate(2322) FIXME: Object literal may only specify known properties, ... Remove this comment to see the full error message
                user: action.payload,
                registrationError: null,
            };
            break;
        case REGISTER_USER_FAILED:
            state = {
                ...state,

                // @ts-expect-error ts-migrate(2322) FIXME: Object literal may only specify known properties, ... Remove this comment to see the full error message
                user: null,
                loading: false,
                registrationError: action.payload,
            };
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
};

export default account;

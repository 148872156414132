import { takeEvery, fork, put, all, call } from "redux-saga/effects";

//Account Redux states
import { REGISTER_USER } from "./actionTypes";
import { registerUserSuccessful, registerUserFailed } from "./actions";

//Include Both Helper File with needed methods
import {
    postFakeRegister,
    postJwtRegister,
} from "../../../helpers/backend_helper";

// Is user register successfull then direct plot user in redux.
function* registerUser({ payload: { user } }: any): any {
    try {
        if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
            const response = yield call(
                postJwtRegister,
                "/post-jwt-register",
                user
            );
            yield put(registerUserSuccessful(response));
        } else if (process.env.REACT_APP_DEFAULTAUTH === "fake") {
            const response = yield call(
                postFakeRegister,
                "/post-fake-register",
                user
            );
            yield put(registerUserSuccessful(response));
        }
    } catch (error) {
        yield put(registerUserFailed(error));
    }
}

export function* watchUserRegister() {
    yield takeEvery(REGISTER_USER, registerUser);
}

function* accountSaga() {
    yield all([fork(watchUserRegister)]);
}

export default accountSaga;

import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import i18n from "i18next";

// Login Redux States
import { FORGET_PASSWORD } from "./actionTypes";
import { userForgetPasswordSuccess, userForgetPasswordError } from "./actions";
import {
    postJwtForgetPwd,
    postFakeForgetPwd,
} from "../../../helpers/backend_helper";

//Include Both Helper File with needed methods

//If user is send successfully send mail link then dispatch redux action's are directly from here.
function* forgetUser({ payload: { user, history } }: any): any {
    try {
        if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
            const response = yield call(postJwtForgetPwd, "/jwt-forget-pwd", {
                email: user.email,
            });
            if (response) {
                yield put(
                    userForgetPasswordSuccess(
                        i18n.t(
                            "Reset link are sended to your mailbox, check there first"
                        )
                    )
                );
            }
        } else {
            const response = yield call(postFakeForgetPwd, "/fake-forget-pwd", {
                email: user.email,
            });
            if (response) {
                yield put(
                    userForgetPasswordSuccess(
                        i18n.t(
                            "Reset link are sended to your mailbox, check there first"
                        )
                    )
                );
            }
        }
    } catch (error) {
        yield put(userForgetPasswordError(error));
    }
}

export function* watchUserPasswordForget() {
    yield takeEvery(FORGET_PASSWORD, forgetUser);
}

function* forgetPasswordSaga() {
    yield all([fork(watchUserPasswordForget)]);
}

export default forgetPasswordSaga;

import { combineReducers } from "@reduxjs/toolkit";
import { History } from "history";
import { connectRouter } from "connected-react-router";
import Layout from "./layout/reducer";

import Login from "./auth/login/reducer";
import Account from "./auth/register/reducer";
import ForgetPassword from "./auth/forgetpwd/reducer";
import Profile from "./auth/profile/reducer";

const createRootReducer = (history: History) =>
    combineReducers({
        router: connectRouter(history),
        Layout,
        Login,
        Account,
        ForgetPassword,
        Profile,
    });

export type RootState = ReturnType<typeof createRootReducer>;

export default createRootReducer;
